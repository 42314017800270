var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BCard',{staticClass:"container"},[_c('div',{staticClass:"d-flex"},[_c('BButton',{staticClass:"rounded-lg mr-2",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.$router.back()}}},[_c('b-icon-chevron-left')],1),_c('span',{staticClass:"text-[24px] font-semibold my-auto text-black"},[_vm._v("Notification Bar")])],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('BRow',{staticClass:"my-3"},[_c('BCol',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Judul","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BFormGroup',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text-black flex items-center"},[_c('span',{staticClass:"text-md font-medium"},[_vm._v("Judul")]),_c('span',{staticClass:"text-primary"},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('BFormInput',{staticClass:"mb-1",attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Masukan judul notifikasi kamu"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('small',{staticClass:"text-primary"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Deskripsi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BFormGroup',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text-black flex items-center"},[_c('span',{staticClass:"text-md font-medium"},[_vm._v("Deskripsi")]),_c('span',{staticClass:"text-primary"},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('BFormTextarea',{staticClass:"mb-1",attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Masukan deskripsi kamu","rows":"4"},model:{value:(_vm.desc),callback:function ($$v) {_vm.desc=$$v},expression:"desc"}}),_c('small',{staticClass:"text-primary"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('BRow',[_c('BCol',{attrs:{"lg":"6"}},[_c('BFormGroup',{staticClass:"mr-2 text-lg",attrs:{"label":"Link Url"}},[_c('BFormInput',{staticClass:"mb-1",attrs:{"placeholder":"Masukan link URL"},model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}})],1)],1),_c('BCol',{attrs:{"lg":"6"}},[_c('ValidationProvider',{attrs:{"name":"Warna","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BFormGroup',[_c('label',{staticClass:"text-lg",attrs:{"for":"title"}},[_vm._v("Warna")]),_c('div',{staticClass:"p-1 border-[1px] border-[#E2E2E2] rounded-[49px]",staticStyle:{"max-width":"235px"}},_vm._l((_vm.colorOptions),function(items){return _c('button',{key:items.id,staticClass:"color-options rounded-full ml-1",class:items.active ? 'active' : '',attrs:{"id":items.id,"state":items.active ? true : null},on:{"click":function($event){return _vm.setColors(items)}}})}),0),_c('small',{staticClass:"text-primary"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1),_c('BRow',{staticClass:"mt-2"},[_c('ValidationProvider',{attrs:{"label":"Platform","name":"Platform","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BCol',{staticClass:"flex items-center",attrs:{"lg":"12"}},[_vm._l((_vm.platform),function(service){return _c('div',{key:service.id,staticClass:"flex items-center mr-1"},[_c('BFormCheckbox',{model:{value:(service.onCheck),callback:function ($$v) {_vm.$set(service, "onCheck", $$v)},expression:"service.onCheck"}}),_c('img',{staticClass:"w-[100px]",attrs:{"src":service.img_url,"alt":"Komerce"}})],1)}),_c('small',{staticClass:"text-primary"},[_vm._v(_vm._s(errors[0]))])],2)]}}],null,true)})],1)],1),_c('BCol',{attrs:{"md":"6"}},[_c('div',{staticClass:"d-flex justify-content-end mb-1"},[_c('BButton',{staticClass:"rounded-lg",attrs:{"variant":invalid ? 'outline-secondary' : 'outline-primary',"disabled":invalid || _vm.disableBtn || _vm.checkStatus() || !_vm.color},on:{"click":function($event){return _vm.submit('save')}}},[_vm._v(" Draft ")]),_c('BButton',{staticClass:"rounded-lg ml-1",attrs:{"variant":invalid ? 'secondary' : 'primary',"disabled":invalid || _vm.disableBtn || _vm.checkStatus() || !_vm.color},on:{"click":function($event){return _vm.submit('submit')}}},[_vm._v(" Publish ")])],1),_c('div',{staticClass:"bg-[#F8F8F8] d-flex py-2"},[(_vm.colorOptions[0].active )?_c('img',{staticClass:"mx-auto",attrs:{"src":"https://storage.googleapis.com/komerce/assets/banner/preview-notif-success.svg","alt":"Komerce"}}):_vm._e(),(_vm.colorOptions[1].active )?_c('img',{staticClass:"mx-auto",attrs:{"src":"https://storage.googleapis.com/komerce/assets/banner/preview-notif-warning.svg","alt":"Komerce"}}):_vm._e(),(_vm.colorOptions[2].active )?_c('img',{staticClass:"mx-auto",attrs:{"src":"https://storage.googleapis.com/komerce/assets/banner/preview-notif-danger.svg","alt":"Komerce"}}):_vm._e(),(_vm.colorOptions[3].active )?_c('img',{staticClass:"mx-auto",attrs:{"src":"https://storage.googleapis.com/komerce/assets/banner/preview-notif-info.svg","alt":"Komerce"}}):_vm._e()])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }